import { Component, Renderer2, inject, computed, effect, HostListener, signal, viewChild } from '@angular/core';

import { DOCUMENT, NgClass } from '@angular/common';
import { Router, NavigationEnd, RouterOutlet, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { Store } from '@ngrx/store';

import * as navActions from './store/actions/nav.action';
import * as navSelectors from './store/selectors/nav.selector';
import { filter } from 'rxjs/operators';
import { routerFade } from './animations';
import { ChangeDetectionStrategy } from '@angular/core';
import { CookieNoticeComponent } from '@teamfoster/sdk/cookie-ngrx';
import { getRouterState } from './store';
import { getTheme } from './store/selectors/theme.selector';
import { toSignal } from '@angular/core/rxjs-interop';
import { GridOverlayComponent } from './standalone/components/grid-overlay/grid-overlay.component';
import { FromDictionaryPipe, getDictionaryLoaded } from '@teamfoster/sdk/dictionary-ngrx';
import { SideNavComponent } from './navigation/components/side-nav/side-nav.component';
import { getUser, SignOut, SwitchTenant } from './auth/store';
import { MatIconRegistry } from '@angular/material/icon';
import { THEME_LOCALSTORAGE_KEY } from './store/effects/theme.effect';
import { SetTheme } from './store/actions/theme.action';
import { FromMenuPipe } from './navigation/pipes/from-menu.pipe';
import { getMenuLoaded } from './navigation/store';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { CommandPaletteComponent } from './command-palette';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { TenantSwitcherComponent } from './standalone/components/tenant-switcher/tenant-switcher.component';
import { MatProgressBar } from '@angular/material/progress-bar';
import { AuthService } from './auth/services';
import { MenuService } from './navigation/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [routerFade],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet, CookieNoticeComponent, GridOverlayComponent, FromDictionaryPipe, SideNavComponent, FromMenuPipe, MatProgressBar],
})
export class AppComponent {
  private store = inject(Store);
  private router = inject(Router);
  private renderer = inject(Renderer2);
  private auth = inject(AuthService);
  private dlg = inject(Dialog);
  private document: any = inject(DOCUMENT);
  private menu = inject(MenuService);

  private _commandPalette?: DialogRef<any, CommandPaletteComponent>;
  private _tenantSwitcher?: DialogRef<any, TenantSwitcherComponent>;

  private _sideNav = viewChild<SideNavComponent>('sideNav');

  @HostListener('window:keydown.Control.s', ['$event'])
  @HostListener('window:keydown.Meta.s', ['$event'])
  @HostListener('window:keydown.Control.k', ['$event'])
  @HostListener('window:keydown.Meta.k', ['$event'])
  async commandPalette(event: KeyboardEvent | null) {
    event?.preventDefault();
    if (this._commandPalette) {
      this._commandPalette.close();
      this._commandPalette = undefined;
      return;
    }
    this._commandPalette = this.dlg.open(CommandPaletteComponent, {
      minWidth: '40vw',
      maxWidth: '700px',
      width: '100%',
      panelClass: 'autocomplete-dialog',
      data: { tenants: this.user$()?.tenants.map(a => a.tenant), menu: new FromMenuPipe(this.menu).transform('main-menu') },
    });
    const result = await firstValueFrom(this._commandPalette.closed);
    if (result?.menuId) {
      this._sideNav()?.activateMenuItem(result.menuId);
    }
    this._commandPalette = undefined;
  }

  @HostListener('window:keydown.Control.p', ['$event'])
  @HostListener('window:keydown.Meta.p', ['$event'])
  async tenantSwitcher(event: KeyboardEvent | null) {
    event?.preventDefault();
    if (this._tenantSwitcher) {
      this._tenantSwitcher.close();
      this._tenantSwitcher = undefined;
      return;
    }
    this._tenantSwitcher = this.dlg.open(TenantSwitcherComponent, {
      minWidth: '40vw',
      maxWidth: '700px',
      width: '100%',
      panelClass: 'autocomplete-dialog',
      data: { tenants: this.user$()?.tenants.map(a => a.tenant) },
    });
    await firstValueFrom(this._tenantSwitcher.closed);
    this._tenantSwitcher = undefined;
  }

  @HostListener('window:beforeunload', ['$event'])
  async beforeUnloadHandler(event: any) {
    await lastValueFrom(this.auth.updateLastActivity());
  }

  routeState$ = this.store.selectSignal(getRouterState);
  menuOpen$ = this.store.selectSignal(navSelectors.getMenuOpen);
  theme$ = this.store.selectSignal(getTheme);
  themeEffect$ = effect(() => this.updateTheme(this.theme$()));
  user$ = this.store.selectSignal(getUser);
  activeTenant = computed(() => this.user$()?.selectedTenant);
  dictionaryLoaded$ = this.store.selectSignal(getDictionaryLoaded);
  menuLoaded$ = this.store.selectSignal(getMenuLoaded);

  loaded$ = computed(() => this.dictionaryLoaded$() && this.menuLoaded$());

  tenantChange = effect(() => {
    const tenant = this.activeTenant();

    this.setTenantTheme(tenant?.cssTheme);
  });

  hasSidebarItems$ = signal(false);

  private routerEvents$ = toSignal(
    this.router.events.pipe(filter(e => [NavigationStart, NavigationEnd, NavigationCancel, NavigationError].some(b => e instanceof b)))
  );
  loading$ = computed(
    () =>
      !(
        this.routerEvents$() instanceof NavigationEnd ||
        this.routerEvents$() instanceof NavigationCancel ||
        this.routerEvents$() instanceof NavigationError
      )
  );

  domainName = this.document.location.hostname;
  testGridEnabled = false;
  routerAnimationState = '';

  menuOpenEffect$ = effect(() => this.updateRoot(this.menuOpen$()));
  routerEffect$ = effect(() => {
    const event = this.routerEvents$();
    const state = this.routeState$();

    if (event instanceof NavigationStart && event.navigationTrigger === 'imperative' && !state?.state?.queryParams) {
      window.scrollTo(0, 0);
    }
    this.document.querySelector('body').classList.add('set--in');
    this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  });

  constructor(matIconRegistry: MatIconRegistry) {
    matIconRegistry.setDefaultFontSetClass('material-symbols-rounded');

    const theme = localStorage.getItem(THEME_LOCALSTORAGE_KEY) || 'light';
    if (theme) {
      this.store.dispatch(SetTheme({ theme }));
    }
  }

  toggleMenu() {
    this.store.dispatch(navActions.ToggleMenu());
  }

  closeMenu() {
    this.store.dispatch(navActions.CloseMenu());
  }

  openMenu() {
    this.store.dispatch(navActions.OpenMenu());
  }

  signOut() {
    this.store.dispatch(SignOut());
  }

  switchTenant(tenantId: string) {
    this.store.dispatch(SwitchTenant({ tenantId }));
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }

  private updateRoot(menuActive: boolean) {
    menuActive
      ? this.renderer.addClass(this.document.body, 'main-nav--active')
      : this.renderer.removeClass(this.document.body, 'main-nav--active');
  }

  private updateTheme(theme: string) {
    const bodyClasses: string[] = Array.from(this.document.body.classList);
    const possibleThemes = ['light', 'dark'];
    const prevThemeClass = bodyClasses.find(c => possibleThemes.includes(c));

    if (prevThemeClass) {
      this.renderer.removeClass(this.document.body, prevThemeClass);
    }

    this.renderer.addClass(this.document.body, theme);
  }

  setTenantTheme(styles?: string) {
    // remove old tenant theme
    const oldStyle = document.getElementById('tenant-theme');
    if (oldStyle) {
      oldStyle.remove();
    }

    if (!styles) {
      return;
    }

    const styleTag = document.createElement('style');
    styleTag.id = 'tenant-theme';
    styleTag.innerHTML = styles;

    this.renderer.appendChild(document.head, styleTag);
  }
}
