import { provideHttpClient, withInterceptorsFromDi, withFetch } from '@angular/common/http';
import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { ProvideIcons } from '@teamfoster/sdk/icon';
import { ImageSharpConfig, ProvideOptimisedImageProcessor } from '@teamfoster/sdk/image';
import { environment } from 'src/environments/environment';
import { routes } from './app.routes';
import { reducers, metaReducers, appEffects, CustomSerializer } from './store';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CookieConfig, ProvideCookiesNgrx } from '@teamfoster/sdk/cookie-ngrx';
import { ProvideDictionaryNgrx } from '@teamfoster/sdk/dictionary-ngrx';

import * as fromAuth from './auth/store';
import * as fromNavigation from './navigation/store';
import * as fromCommandPalette from './command-palette/store';
import * as fromContent from './content/store';
import * as fromMedia from './media/store';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideNativeDateAdapter } from '@angular/material/core';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/quote.min.js';
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/languages/nl.js';

import { nl } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
setDefaultOptions({ locale: nl });

const cookieConfig: CookieConfig = {
  analyticsCode: 'UA-0000000-0',
  strippedContentPlaceholder: 'Deze inhoud wordt niet getoond omdat je geen marketing cookies hebt geaccepteerd',
  buttonSettings: 'Instellingen',
  buttonAcceptAll: 'Accepteer alles',
  buttonClose: 'Sluiten',
  strippedContentButton: 'Cookie-instellingen wijzigen',
  types: {
    analytical: true,
    functional: true,
    marketing: false,
  },
  showSettings: true,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(
      routes,
      withComponentInputBinding()
      // withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })
    ),
    provideStore(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    provideState(fromAuth.featureKey, fromAuth.reducers),
    provideState(fromNavigation.featureKey, fromNavigation.reducers),
    provideState(fromCommandPalette.featureKey, fromCommandPalette.reducers),
    provideState(fromCommandPalette.featureKey, fromCommandPalette.reducers),
    provideState(fromContent.featureKey, fromContent.reducers),
    provideState(fromMedia.featureKey, fromMedia.reducers),
    provideEffects({
      ...appEffects,
      ...fromAuth.effects,
      ...fromNavigation.effects,
      ...fromCommandPalette.effects,
      ...fromContent.effects,
      ...fromMedia.effects,
    }),
    provideRouterStore({ serializer: CustomSerializer }),
    provideStoreDevtools({ maxAge: 25, logOnly: environment.production }),
    ProvideCookiesNgrx(cookieConfig),
    ProvideDictionaryNgrx({}),
    provideNativeDateAdapter(),
    ProvideIcons({ spritesheetUrl: 'assets/dist/svg/symbol/svg/sprite.symbol.svg' }),
    ProvideOptimisedImageProcessor(ImageSharpConfig, {
      placeholderUrl: 'https://www.teamfoster.nl/media/uploads/placeholder.png',
    }),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(FroalaEditorModule.forRoot(), FroalaViewModule.forRoot()),
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  ],
};
